import packageJson from '../../../package.json'

export default function getParticipantsPrintData() {
	try {
		const tournament = JSON.parse(sessionStorage.getItem('tournament'))
		const participants = JSON.parse(sessionStorage.getItem('participants'))

		// Изменил: tables -> participants, stage -> null, добавил match_type

		return {
			version: packageJson.version,
			environment: window.location.hostname,
			tournament_name: tournament.name,
			description: tournament.start_date,
			stage: null,
			participants: participants,
			page: 'participants',
			match_type: tournament.type
		}
	} catch {
		return null
	}
}