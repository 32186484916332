import { createSlice } from '@reduxjs/toolkit'

import { getLocalStorageItem, removeItemInLocalStorage, setItemInLocalStorage } from '../../utils/functions'

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		authorized: getLocalStorageItem('authorized') === 'true' ? true : false,
		access: getLocalStorageItem('access'),
		refresh: getLocalStorageItem('refresh'),
		isGuest: false,
		accessExpiresAt: Number(getLocalStorageItem('accessExpiresAt')),
		refreshTokenExpiresAt: Number(getLocalStorageItem('refreshTokenExpiresAt')),
		timeDelta: Number(getLocalStorageItem('timeDelta')),
	},
	reducers: {
		setAuthorized(state, action) {
			setItemInLocalStorage('authorized', action.payload)
			state.authorized = action.payload
		},
		setAccessToken(state, action) {
			setItemInLocalStorage('access', action.payload)
			state.access = action.payload
		},
		setAccessTokenTimeExpiration(state, action) {
			setItemInLocalStorage('accessExpiresAt', action.payload)
			state.accessExpiresAt = action.payload
		},
		setRefreshTokenTimeExpiration(state, action) {
			setItemInLocalStorage('refreshTokenExpiresAt', action.payload)
			state.refreshTokenExpiresAt = action.payload
		},
		setRefreshToken(state, action) {
			setItemInLocalStorage('refresh', action.payload)
			state.refresh = action.payload
		},
		setTimeDelta(state, action) {
			setItemInLocalStorage('timeDelta', action.payload)
			// разница  между временем создания токена и временем девайсапользователя
			state.timeDelta = action.payload
		},
		setIsGuest(state, action){
			console.log('setIsGuest action.payload: ', action.payload)
			if (action.payload) {
				setItemInLocalStorage('isGuest', action.payload)
			} else {
				removeItemInLocalStorage('isGuest')
			}

			state.isGuest = action.payload
		},
		restoreAuth(state, action) {
			const data = action.payload
			state.authorized = true
			state.access = data.storageAccess
			state.refresh = data.storageRefresh
			state.accessExpiresAt = data.storageAccessExpiresAt
			state.refreshTokenExpiresAt = data.storageRefreshExpiresAt
			state.timeDelta = data.timeDelta
		},
		resetAuth(state) {
			localStorage.clear()
			state.authorized = false
			state.access = null
			state.refresh = null
			state.accessExpiresAt = null
			state.refreshTokenExpiresAt = null
			state.timeDelta = null
		}
	}
})

export const {
	setAuthorized,
	setAccessToken,
	setRefreshToken,
	setIsGuest,
	resetAuth,
	setAccessTokenTimeExpiration,
	setRefreshTokenTimeExpiration,
	restoreAuth,
	setTimeDelta
} = authSlice.actions
export default authSlice.reducer