import { createSlice } from '@reduxjs/toolkit'

import { getBooleanValueFromSession } from '../../utils/functions2'

const playersSlice = createSlice({
	name: 'players',
	initialState: {
		players: [],
		initPlayers: [],
		addPlayersByList: getBooleanValueFromSession('addPlayersByList')
	},
	reducers: {
		setCommunityPlayers(state, action) {
			state.players = action.payload
		},
		setInitPlayers(state, action) {
			state.initPlayers = action.payload
		},
		setAddPlayersByList(state, action) {
			state.addPlayersByList = action.payload
		}
	}
})

export const {
	setCommunityPlayers,
	setInitPlayers,
	setAddPlayersByList
} = playersSlice.actions
export default playersSlice.reducer