import { memo, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ClipboardJS from 'clipboard'

import './HeaderTournament.scss'
import { ReactComponent as ChainIcon } from '../../icons/chain.svg'
import { getParameters } from '../../utils/functions.js'
import { selectAuth, selectTournament, selectTournamentOptions } from '../../redux/selectors.js'
import PrintButton from '../../print/PrintButton/PrintButton.jsx'

function HeaderTournament({ date, category, copyLink }) {
	const { t } = useTranslation()
	const { tournamentParams } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const { auth_code, name } = useSelector(selectTournament)
	const { authorized, isGuest } = useSelector(selectAuth)
	const options = useSelector(selectTournamentOptions)
	const { communityColor } = useSelector(state => state.community)
	const buttonRef = useRef(null)

	useEffect(() => {
		if (!auth_code || !copyLink || !buttonRef.current) return

		const guestUrl = `${window.location.origin}/t/${tournamentUid}-${auth_code}/draws/1`

		const clipboard = new ClipboardJS(buttonRef.current, {
			text: () => guestUrl
		})

		clipboard.on('success', (e) => {
			alert(t('Link copied'))

			e.clearSelection()
		})

		clipboard.on('error', () => {
			alert(t('Error copying link'))
		})

		return () => clipboard.destroy()
	}, [t, copyLink, auth_code, tournamentUid])

	function useShowPrintButtonURL() {
		const location = useLocation()

		return /\/(draws|schedule|participants)\b/.test(location.pathname)
	}
	const isMatch = useShowPrintButtonURL()

	return (
		<div
			className="header-tournament"
			style={{ borderBottom: communityColor ? `8px solid ${communityColor}` : '' }}
		>
			<h1 className="header-tournament__title">
				{ name || '' }
			</h1>

			<div className="header-tournament__wrapper">
				<div className="header-tournament__container">
					<p className="header-tournament__date">
						{ date }
					</p>

					{
						category && (
							<p className="header-tournament__categories">
								{ category }
							</p>
						)
					}
				</div>

				{
					authorized && !isGuest && (
						<div className="header-tournament__copy-wrapper">
							{
								isMatch &&
									<PrintButton />
							}

							{
								copyLink && (
									<button
										className="header-tournament__copy-button"
										ref={buttonRef}
									>
										<ChainIcon className="chain-icon" />
									</button>
								)
							}
						</div>
					)
				}
			</div>
		</div>
	)
}

export default memo(HeaderTournament)