const TYPE_SINGLES = 'singles'
const TYPE_DOUBLES = 'doubles'
const TYPE_TEAM = 'team'

const GROUP_TYPE_RR = 'round_robin'
const GROUP_TYPE_OLYMPIC_PLUS = 'olympic_plus'
const GROUP_TYPE_NOT_PLAYING = 'no_play'
const GROUP_TYPE_DROP_OUT = 'drop_out'
const GROUP_TYPE_OLYMPIC = 'olympic'
const GROUP_TYPE_OLYMPIC_THREE = 'olympic_three'

const NODE_TYPE_IN = 'incoming'
const NODE_TYPE_OUT = 'outgoing'
const NODE_TYPE_MIDDLE = 'middle'

const RESULT_COLUMN_NAME = 'Result'
const FINAL_COLUMN_NAME = 'Final'

const POINTS_PERCENTAGE = 'points_percentage'
const POINTS_DIFFERENCE = 'points_difference'
const POINTS_PERCENTAGE_BETWEEN_DISPUTANTS = 'points_percentage_between_disputants'
const POINTS_DIFFERENCE_BETWEEN_DISPUTANTS = 'points_difference_between_disputants'

const POINTS_DIFFERENCE_TEXT = 'По разнице выигранных сетов/геймов'
const POINTS_DIFFERENCE_BETWEEN_DISPUTANTS_TEXT = 'По разнице выигранных сетов/геймов между спорящими'
const POINTS_PERCENTAGE_TEXT = 'По проценту выигранных сетов/геймов'
const POINTS_PERCENTAGE_BETWEEN_DISPUTANTS_TEXT = 'По проценту выигранных сетов/геймов между спорящими'

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

const T1 = 'tier1'
const T2 = 'tier2'
const T3 = 'tier3'
const T4 = 'tier4'
const EXPIRES_MS_AMOUNT_LIMIT = 1 * 60 * 1000

const CREATE = 'create'
const FILLED = 'filled'
const GENERATE = 'generate'
const EDIT = 'edit'
const GENERATION = 'generation'
const BUILD = 'build'

const STRUCTURE = 'structure'
const DRAWS = 'draws'
const SCHEDULE = 'schedule'

const CONNECTION_ERROR = 'FETCH_ERROR'


const EMPTY_NODE = {
	placed: false,
	name: null,
	orderNumber: 0,
	playerUid: null,
	doubleUid: null,
	participant: {},
	rating: null,
	empty: true,
}

const NEW_GROUP = {
	'type': GROUP_TYPE_RR,
	'participants_count': 0,
	'incoming_nodes_count': 0,
	'outgoing_nodes_count': 0,
	'participants_max': 1,
	'nodes': [],
	'matches': []
}

const FULFILLED ='fulfilled'
const PENDING = 'pending'

const NEW_SET_BASIC = {
	order_number: 1,
	score1: 0,
	score2: 0,
	tie_break_score1: null,
	tie_break_score2: null
}
const NEW_SET_ADVANCE = {
	order_number: 1,
	score1: 6,
	score2: 6,
	tie_break_score1: null,
	tie_break_score2: null
}

const NEW_PLAYER_BY_LIST = {
	firstName: '',
	lastName: '',
	error: '',
	isBusy: false,
	inCommunity: false,
	index: null
}

const incorrectOldPassword = 'Old password is incorrect.'
const emailExistsErr = 'пользователь с таким email уже существует.'
const emailIsWrongErr = 'Введите правильный адрес электронной почты.'
const phoneExistsErr = 'пользователь с таким mobile уже существует.'

const excludedErrors = [
	incorrectOldPassword,
	emailExistsErr,
	emailIsWrongErr,
	phoneExistsErr
]

const RANDOM = 'random'
const STRENGTH ='strength'
const NOSORT = 'nosort'
const GENDER = 'gender'

const SET = 'set'
const GAME = 'game'

const loginRequest = '/token/'
const registrationRequest = '/users/'

const requestsWithoutAuth = [loginRequest, registrationRequest]

const ACCESS = 'access'
const REFRESH = 'refresh'
const ACCESS_EXPIRES_AT = 'accessExpiresAt'
const REFRESH_EXPIRES_AT = 'refreshTokenExpiresAt'

const POST = 'POST'
const GET = 'GET'
const PUT = 'PUT'
const PATCH = 'PATCH'

const AUTH_ERROR_STATUS = 401
const SERVER_ERROR_STATUS = 500

const COMMUNITY_COLORS = ['#D4D8CE', '#D4E9CC', '#D6EEFF', '#E4DDF2', '#EAEDAA', '#EAD9D5']

export {
	EXPIRES_MS_AMOUNT_LIMIT,
	TYPE_SINGLES,
	TYPE_DOUBLES,
	TYPE_TEAM,
	GROUP_TYPE_RR,
	GROUP_TYPE_OLYMPIC_PLUS,
	GROUP_TYPE_NOT_PLAYING,
	GROUP_TYPE_DROP_OUT,
	GROUP_TYPE_OLYMPIC,
	NODE_TYPE_IN,
	NODE_TYPE_OUT,
	NODE_TYPE_MIDDLE,
	RESULT_COLUMN_NAME,
	FINAL_COLUMN_NAME,
	POINTS_PERCENTAGE,
	POINTS_PERCENTAGE_BETWEEN_DISPUTANTS,
	POINTS_DIFFERENCE,
	POINTS_DIFFERENCE_BETWEEN_DISPUTANTS,
	POINTS_PERCENTAGE_TEXT,
	POINTS_DIFFERENCE_TEXT,
	POINTS_PERCENTAGE_BETWEEN_DISPUTANTS_TEXT,
	POINTS_DIFFERENCE_BETWEEN_DISPUTANTS_TEXT,
	ALPHABET,
	T1,
	T2,
	T3,
	T4,
	CREATE,
	FILLED,
	GENERATE,
	EDIT,
	EMPTY_NODE,
	NEW_GROUP,
	FULFILLED,
	GROUP_TYPE_OLYMPIC_THREE,
	NEW_SET_BASIC,
	NEW_SET_ADVANCE,
	CONNECTION_ERROR,
	GENERATION,
	BUILD,
	STRUCTURE,
	SCHEDULE,
	DRAWS,
	NEW_PLAYER_BY_LIST,
	PENDING,
	incorrectOldPassword,
	emailExistsErr,
	excludedErrors,
	RANDOM,
	STRENGTH,
	NOSORT,
	GENDER,
	SET,
	GAME,
	requestsWithoutAuth,
	ACCESS,
	REFRESH,
	ACCESS_EXPIRES_AT,
	REFRESH_EXPIRES_AT,
	POST,
	GET,
	PUT,
	PATCH,
	AUTH_ERROR_STATUS,
	SERVER_ERROR_STATUS,
	COMMUNITY_COLORS
}