import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import './AdditionPlayersByList.scss'
import {
	selectCommunity,
	selectParticipants,
	selectTournament,
	selectTournamentOptions
} from '../../redux/selectors'
import InputLight from '../../reusableComponents/InputLight/InputLight'
import {
	setAddParticipantsByList,
	setAddedPlayersByList,
	setNoSortedParticipants,
	setParticipants
} from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import { useAddPlayerMutation } from '../../redux/playersService/playersApiSlice'
import { getParticipantsWithNewPlayer } from '../../utils/functions2'
import { NEW_PLAYER_BY_LIST, TYPE_DOUBLES, TYPE_SINGLES } from '../../utils/constants'
import RoundedButton from '../../reusableComponents/RoundedButton/RoundedButton'
import { setAddPlayersByList } from '../../redux/playersService/playersSlice'

import {
	getCleanedPlayersFromSpaces,
	getDoubles,
	validateNewPLayersList
} from './externalFunctions'

function AdditionPlayersByList({ communtyPlayers, isTournament }) {
	const dispatch = useDispatch()
	const { communityUid } = useSelector(selectCommunity)
	const { addedPlayersByList, participants } = useSelector(selectParticipants)
	const { type } = useSelector(selectTournament)
	const { settings = {} } = useSelector(selectTournamentOptions)
	const [createPlayer] = useAddPlayerMutation()

	const [requestIsActive, setRequestIsActive] = useState(false)
	const [currentInputIndex, setCurrentInputIndex] = useState(0)

	const participantsCount = type === TYPE_SINGLES || !isTournament ? addedPlayersByList.length : addedPlayersByList.length / 2
	const limitReached = participants?.length + participantsCount > settings?.maxParticipants - 1

	useEffect(() => {
		const inputOne = {
			...NEW_PLAYER_BY_LIST,
			index: 0
		}
		const inputTwo = {
			...NEW_PLAYER_BY_LIST,
			index: 1
		}

		if (type === TYPE_SINGLES || !isTournament) {
			// добавляем пустые инпуты для добавления списком
			dispatch(setAddedPlayersByList([inputOne]))
		} else {
			dispatch(setAddedPlayersByList([inputOne, inputTwo]))
		}
	}, [])

	function handleOnChange(e, index, field) {
		const value = e.target.value
		const secondField = field === 'firstName' ? 'lastName' : 'firstName'

		const playerObj = {
			index,
			[field]: !value.length ? '' : value,
			[secondField]: addedPlayersByList[index][secondField],
			error: '',
			inCommunity: false
		}

		let updatedList = [...addedPlayersByList]

		updatedList[index] = playerObj

		if (currentInputIndex !== index) {
			updatedList = getCleanedPlayersFromSpaces(updatedList)
			setCurrentInputIndex(index)
		}

		dispatch(setAddedPlayersByList(updatedList))
		addNewInput(index, updatedList)
	}

	function handleOnClick(inputIndex) {
		addNewInput(inputIndex)
	}

	function addNewInput(clickedIndex, addedPlayers) {
		if (limitReached) return

		const players = addedPlayers ? addedPlayers : addedPlayersByList

		const inputsArrayLegth = players.length
		const lastInput = players[inputsArrayLegth - 1]
		const prevInput = players[inputsArrayLegth - 2]

		if (inputsArrayLegth < 2 ||
			(
				lastInput.lastName.trim().length < 1 &&
				prevInput && prevInput.lastName.trim().length > 0 &&
				clickedIndex === inputsArrayLegth - 1
			) || lastInput.lastName.trim().length > 0
		) {
			const inputOne = {
				...NEW_PLAYER_BY_LIST,
				index: inputsArrayLegth
			}
			const inputTwo = {
				...NEW_PLAYER_BY_LIST,
				index: inputsArrayLegth + 1
			}

			const inputs = type === TYPE_SINGLES || !isTournament ? [inputOne] : [inputOne, inputTwo]

			const updatedList = [...players, ...inputs]
			dispatch(setAddedPlayersByList(updatedList))
		}
	}

	function addInTournamentList(futureParticipants) {
		let updatedParticipants = futureParticipants

		if (type === TYPE_DOUBLES) {
			// фомируем пары если турнир парный
			const { pairs } = getDoubles(futureParticipants, type)
			updatedParticipants = pairs
		}

		dispatch(setParticipants({
			participants: [...participants, ...updatedParticipants]
		}))

		dispatch(setNoSortedParticipants([...participants, ...updatedParticipants]))
	}

	async function saveInDB(player, participantsState) {
		const { lastName, firstName } = player

		const body = {
			last_name: lastName,
			first_name: firstName ? firstName : '',
			middle_name: '',
			community_uids: [communityUid]
		}

		try {
			const response = await createPlayer({ body }).unwrap()

			const { newPlayer } = getParticipantsWithNewPlayer(response, participantsState)

			return newPlayer
		} catch (e) {
			setRequestIsActive(false)
			console.log('save player in DB catch error ', e)
		}
	}

	async function addPlayers() {
		setRequestIsActive(true)
		const { checkedList, isValid, foundInCommunity } =
			validateNewPLayersList(addedPlayersByList, participants, type, communtyPlayers, isTournament)

		if (!isValid) {
			dispatch(setAddedPlayersByList(checkedList))
			setRequestIsActive(false)
			return
		}

		let futureParticipants = []

		for (let i = 0; i < checkedList.length; i++) {
			const player = checkedList[i]

			if (player.lastName.length < 1) {
				continue
			}

			if (player.inCommunity && isTournament) {
				// если игрок существует в сообществе и не здействован в турнир
				const firstFoundedInCommunity = foundInCommunity[`${player.index}`]
				futureParticipants = [...futureParticipants, firstFoundedInCommunity[0]]
				continue
			}

			// если игрока еще нет в сообществе
			const newPlayer = await saveInDB(player, futureParticipants)
			futureParticipants = [...futureParticipants, newPlayer]
		}

		if (isTournament) {
			addInTournamentList(futureParticipants)
			dispatch(setAddParticipantsByList(false))
		} else if (!isTournament) {
			dispatch(setAddPlayersByList(false))
		}

		setRequestIsActive(false)
	}

	return (
		<div className="addition-players-bylist">
			<div className="addition-players-bylist__wrapper">
				{
					addedPlayersByList.map((player, index) => (
						<div
							key={index}
							className="addition-players-bylist__inputs-container"
							style={{
								marginBottom: type === TYPE_DOUBLES && (index + 1) % 2 === 0 ? '30px' : ''
							}}
						>
							<div className="addition-players-bylist__inputs">
								<InputLight
									value={player.lastName}
									onChange={(e) => handleOnChange(e, index, 'lastName')}
									onClick={() => handleOnClick(index)}
									maxLength={64}
									touched={true}
									placeholder={'Фамилия'}
									error={player.error ? true: false}
									styles={{
										marginRight: '20px'
									}}
								/>

								<InputLight
									value={player.firstName}
									onChange={(e) => handleOnChange(e, index, 'firstName')}
									onClick={() => handleOnClick(index)}
									maxLength={64}
									touched={true}
									placeholder={'Имя'}
									error={player.error ? true: false}
								/>
							</div>

							{player.error && <span className="addition-players-bylist__input-error">{player.error}</span>}
						</div>
					))
				}
			</div>

			<RoundedButton
				title={'Сохранить'}
				onClick={addPlayers}
				loading={requestIsActive}
			/>
		</div>
	)
}

export default AdditionPlayersByList