import { createApi } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'

import { tokenIsNotExpired, resetAuthorization } from '../utils/functions'
import {
	AUTH_ERROR_STATUS,
	CONNECTION_ERROR,
	requestsWithoutAuth,
	SERVER_ERROR_STATUS
} from '../utils/constants'
import { isCreateComunityRequest, isExeptedError, setTokenData } from '../utils/functions2'

import { setError } from './errorService/errorSlice'
import { getNewAccess, refreshToken } from './authService/refreshApiSlice'
import { sendLogInTg } from './logService/telegramApi'
import { restoreAuth } from './authService/authSlice'
import { getAuthStorageData, getOutpuString } from './apiExternalFunctions'
import { basicRequest } from './api/basicApi'

const mutex = new Mutex()

const baseQueryWithReauth = async (args, api, extraOptions) => {
	await mutex.waitForUnlock()

	const { dispatch, getState } = api
	const state = getState()
	const { isGuest, timeDelta } = state.auth
	const authState = state.auth
	let {
		authorized,
		refresh,
		access,
		accessExpiresAt,
		refreshTokenExpiresAt
	} = authState
	const { communityUid } = state.community

	const authStorageData = getAuthStorageData(state.auth)
	const reduxCondition = { ...authState }
	let tgOutput = { authStorageData, reduxCondition }

	let result = null

	try {
		if (!mutex.isLocked()) {
			const release = await mutex.acquire()

			if (
				(!authorized || !access || !refresh) &&
				!isGuest && !requestsWithoutAuth.includes(args.url) &&
				!isCreateComunityRequest(args)
			) {
				// проверка авторизации
				if (
					authStorageData.storageAccess
					&& authStorageData.storageRefresh
					&& authStorageData.storageAccessExpiresAt
					&& authStorageData.storageRefreshExpiresAt
				) {
					dispatch(restoreAuth(authStorageData))

					refresh = authStorageData.storageRefresh
					access = authStorageData.storageAccess
					accessExpiresAt = authStorageData.storageAccessExpiresAt
					refreshTokenExpiresAt = authStorageData.storageRefreshExpiresAt
					authorized = true

					release()
					await sendLogInTg(api, extraOptions, 'Авторизация была утеряна и восстановлена из storage')
				} else {
					tgOutput = {
						...tgOutput,
						message: 'В редаксе и в storage нет данных об авторизации',
					}

					const resultString = getOutpuString(tgOutput)
					await sendLogInTg(api, extraOptions, resultString)

					resetAuthorization(dispatch, communityUid, 'no_token/s')
					release()
					return { error: { status: AUTH_ERROR_STATUS } }
				}
			}

			const usedCondition = {
				refresh: JSON.stringify(refresh),
				access: JSON.stringify(access)
			}
			const currentTime = new Date().getTime()

			tgOutput = {
				...tgOutput,
				usedCondition,
				currentTime
			}

			if (authorized && !tokenIsNotExpired(accessExpiresAt, timeDelta) && !isGuest) {
				// если access просрочен
				const response = await refreshToken(
					refresh, api, extraOptions, dispatch, refreshTokenExpiresAt, timeDelta
				)

				if (!response.success) {
					tgOutput = {
						...tgOutput,
						message: 'Refresh token истек, сброс авторизации'
					}

					const resultString = getOutpuString(tgOutput)
					await sendLogInTg(api, extraOptions, resultString)

					resetAuthorization(dispatch, communityUid, 'refresh_token_time_expired')
					release()
					return { error: { status: AUTH_ERROR_STATUS } }
				} else {
					access = response.access
				}
			}

			try {
				result = await basicRequest(args, api, extraOptions, access, isGuest)

				if (result && result?.error) {
					const error = result?.error || {}
					const { status, originalStatus, data = {} } = error

					const isException = isExeptedError(data)

					tgOutput = {
						...tgOutput,
						bearer: result?.meta?.request?.headers?.get('Authorization'),
						xAuthCode: result?.meta?.request?.headers?.get('X-Auth-Code'),
						apiError: {
							url: result?.meta?.request?.url,
							method: result?.meta?.request?.method,
							body: args?.body ? JSON.stringify(args?.body, null, 3) : null,
							error: result?.error,
						}
					}

					if (
						(status !== AUTH_ERROR_STATUS || originalStatus === SERVER_ERROR_STATUS || status === CONNECTION_ERROR)
						&& !isException
					) {
						const resultString = getOutpuString(tgOutput)

						dispatch(setError(error))
						await sendLogInTg(api, extraOptions, resultString)
					} else if (status === AUTH_ERROR_STATUS && authorized) {
						// если все таки сервер отдал 401
						tgOutput = {
							...tgOutput,
							message: 'Сервер не принял token. Обновляем access',
						}

						const resultString = getOutpuString(tgOutput)
						await sendLogInTg(api, extraOptions, resultString)

						const accessResult = await getNewAccess(api, extraOptions, refresh)

						if (accessResult?.data?.access) {
							setTokenData(accessResult?.data.access, dispatch)

							result = await basicRequest(args, api, extraOptions)

							await sendLogInTg(api, extraOptions, 'Сервер принял access')
						} else {
							tgOutput = {
								...tgOutput,
								message: 'Не удалось сделать дополнительное обновление токена. Сброс авторизации',
								apiError: accessResult?.error
							}

							const resultString = getOutpuString(tgOutput)

							await sendLogInTg(api, extraOptions, resultString)
							resetAuthorization(dispatch, communityUid, 'refresh_token_invalid')
							return
						}

					}
				}

				console.log(result)

				return result
			} catch (e) {
				const resultString = getOutpuString({ ...tgOutput, args, err: e })

				await sendLogInTg(api, extraOptions, resultString)
				console.log('basic api catch error', e)
			} finally {
				release()
			}
		} else {
			await mutex.waitForUnlock()
			result = await basicRequest(args, api, extraOptions)
		}
	} catch (e) {
		console.log('Basic api common error: ', e)
	}

	return result
}

export const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	endpoints: builder => ({})
})