import { createSlice } from '@reduxjs/toolkit'

import { getSessionStorageItem, parseObject, setItemInSessionStorage } from '../../utils/functions'

import basicOptions from '../../utils/tournamentOptions.json'

const tournamentSlice = createSlice({
	name: 'tournament',
	initialState: {
		tournament: parseObject('tournament', getSessionStorageItem) || {},
		options: parseObject('tournamentOptions', getSessionStorageItem) || {},
		gridSroll: '',
		schedule: [],
	},
	reducers: {
		setTournamentData(state, action) {
			setItemInSessionStorage('tournament', JSON.stringify(action.payload))
			state.tournament = action.payload
		},
		setTournamentOptions(state, action) {
			const options = basicOptions[action.payload ]
			setItemInSessionStorage('tournamentOptions', JSON.stringify(options))
			state.options = options
		},
		setGridToScroll(state, action) {
			state.gridSroll = action.payload
		},
		setSchedule(state, action) {
			state.schedule = action.payload
		}
	}
})

export const { setTournamentData, setTournamentOptions, setGridToScroll, setSchedule } = tournamentSlice.actions
export default tournamentSlice.reducer