import { useEffect, useRef, useState } from 'react'

import ArrowUpDown from '../ArrowUpDown/ArrowUpDown'
import './Select.scss'

function Select({ onChange, value, defaultValue, options, placeholder, name, optionsAbove, blueStyle }) {
	const ref = useRef(null)

	const [activeOption, setActiveOption] = useState(value?.label || '')
	const [opened, setOpened] = useState(false)

	useEffect(() => {
		if (blueStyle) return

		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setOpened(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		document.addEventListener('touchstart', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('touchstart', handleClickOutside)
		}
	}, [ref])

	function openSelect() {
		setOpened(prev => !prev)
	}

	function chooseOption(option) {
		setOpened(false)
		setActiveOption(option.value ? option?.label : '')
		onChange(option)
	}

	function getStyle() {
		const style = {
			top: '7px',
			fontSize: '14px',
		}

		if (value && String(value?.value).length > 0) {
			if (blueStyle) {
				return {
					...style,
					left: '1px',
					color: 'var(--palette-live-grey-7)'
				}
			}

			return style
		}
	}

	return (
		<div ref={ref}>
			<div className={`select ${blueStyle ? 'blue' : ''} ${opened ? 'opened' : ''}`}>
				<label className="select-label" style={getStyle()}>{placeholder}</label>

				<input
					value={activeOption || defaultValue?.label || ''}
					type="text"
					readOnly
					name={name}
					className="select-input"
					onClick={openSelect}
				/>

				<ArrowUpDown onClick={openSelect} isUp={opened} />

				{optionsAbove &&
					<SelectOptions
						options={options}
						value={value}
						opened={opened}
						chooseOption={chooseOption}
						optionsAbove={optionsAbove}
						blueStyle={blueStyle}
					/>
				}
			</div>

			{!optionsAbove &&
				<SelectOptions
					options={options}
					value={value}
					opened={opened}
					chooseOption={chooseOption}
					optionsAbove={optionsAbove}
					blueStyle={blueStyle}
				/>
			}
		</div>
	)
}

export default Select

function SelectOptions({ options, value, opened, chooseOption, optionsAbove, blueStyle }) {
	const optionHeigh = 50

	return (
		<div
			className={`select-options ${optionsAbove ? 'above' : ''}`}
			style={{ height: opened ? options.length * optionHeigh + 'px' : '0px' }}
		>
			{
				options?.map(option => (
					<div
						key={option?.value}
						className={
							`select-options__item ${value?.value === option?.value ? 'active' : ''} ${blueStyle ? 'blue' : ''}`
						}
						onClick={() => chooseOption(option)}
					>
						{option?.value ? option.label : 'Не выбрано'}
					</div>
				))
			}
		</div>
	)
}