import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import './TournamentCalendar.scss'
import { ReactComponent as ArrowBackIcon } from '../../icons/arrow_back.svg'
import MySelect from '../../reusableComponents/Select/Select'
import { ReactComponent as CloseIcon } from '../../icons/close.svg'
import Popup from '../../reusableComponents/Popup/Popup'
import RoundedButton from '../../reusableComponents/RoundedButton/RoundedButton'

// Функция для получения дней в месяце
const getDaysInMonth = (year, month) => {
	const date = new Date(year, month, 0)
	return date.getDate()
}

// Функция для получения дня недели первого числа месяца
const getFirstDayOfMonth = (year, month) => {
	const date = new Date(year, month, 1)
	return date.getDay()
}

const TournamentCalendar = ({
	venues,
	tournamentsMap,
	onClose,
	setTournaments,
	tournaments,
}) => {
	const { t } = useTranslation()
	const currentDate = new Date()
	const [year, setYear] = useState(currentDate.getFullYear())
	const [month, setMonth] = useState(currentDate.getMonth())
	const [selectedVenue, setSelectedVenue] = useState({
		label: '',
		value: ''
	})

	const options = {
		animationEnded(slider) {
			// setIndexOfSlider1(slider.track.details.rel)
			// setSliderIsMoving(false)
		},
		animationStarted() {
			// setSliderIsMoving(true)
		},
		dragStarted() {
			// setTouchedSlider(prev => { return { ...prev, left: true } })
		},
	}

	const daysInMonth = getDaysInMonth(year, month + 1)
	const firstDay = getFirstDayOfMonth(year, month)
	const monthNames = [
		'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
	]

	const venuesOptions = useMemo(() => {
		const arrayOfObjects = venues?.map((el) => {
			return {
				label: el.name,
				value: el.uid
			}
		})

		arrayOfObjects?.unshift({
			label: t('Not selected'),
			value: ''
		})

		return arrayOfObjects
	}, [venues, t])

	const tournamentsInCurrentPeriod = useMemo(() => {
		if (!tournamentsMap) {
			return new Map()
		}

		const itemsOfYear = tournamentsMap.get(year)

		if (!itemsOfYear) return new Map()

		const itemsOfMonth = itemsOfYear.get(month)

		return itemsOfMonth || new Map()
	}, [tournamentsMap, year, month])

	const handlePrevMonth = () => {
		if (month === 0) {
			setYear(year - 1)
			setMonth(11)
		} else {
			setMonth(month - 1)
		}
	}

	const handleNextMonth = () => {
		if (month === 11) {
			setYear(year + 1)
			setMonth(0)
		} else {
			setMonth(month + 1)
		}
	}

	const renderDays = () => {
		const days = []
		const prevMonthDaysInMonth = getDaysInMonth(year, month) // Количество дней в предыдущем месяце

		// Заполняем пустые дни из предыдущего месяца
		for (let i = 0; i < firstDay; i++) {
			days.push(
				<div key={`empty-${i}`} className="empty-day">
					{prevMonthDaysInMonth - firstDay + i + 1}
				</div>
			)
		}

		// Заполняем дни текущего месяца
		for (let day = 1; day <= daysInMonth; day++) {
			const toursInDay = tournamentsInCurrentPeriod.get(day)
			let toursArr = []

			if (toursInDay && selectedVenue.value) {
				toursArr = toursInDay.filter(item => {
					if (selectedVenue.value === item.venue_uid) {
						return item
					}

					return undefined
				})
			} else if (toursInDay) {
				toursArr = toursInDay
			}

			days.push(
				<div onClick={() => showTournamentsForDay(toursArr)} key={day} className={`day ${toursArr.length > 0 ? 'active' : ''}`}>
					{day}
				</div>
			)
		}

		return days
	}

	function showTournamentsForMonth() {
		const list =  [].concat(...Array.from(tournamentsInCurrentPeriod.values()))
		setTournaments(list)
		onClose(false)
	}

	function showTournamentsForDay(tours) {
		setTournaments(tours)
		onClose(false)
	}

	function resetList() {
		setTournaments(tournaments || [])
		onClose(false)
	}

	return (
		<Popup arrowBack onBack={() => onClose(false)}>
			<div className="tournament-calendar__container">

				<div className="tournament-calendar__header">
					<h2>{`${monthNames[month]} ${year}`}</h2>

					<div className="tournament-calendar__arrows">
						<button
							className="tournament-calendar__arrow up"
							onClick={handlePrevMonth}
						>
							<ArrowBackIcon />
						</button>

						<button
							className="tournament-calendar__arrow down"
							onClick={handleNextMonth}
						>
							<ArrowBackIcon />
						</button>
					</div>
				</div>

				{/* <div className="slider">
					<Slider
						initIdx={1}
						length={monthNames.length}
						values={monthNames}
						loop={true}
						options={options}
						setSlider={setSlider}
						setLoaded={setLoaded}
					/>
				</div> */}

				<div className="tournament-calendar__days">
					<div className="day-name">Пн</div>
					<div className="day-name">Вт</div>
					<div className="day-name">Ср</div>
					<div className="day-name">Чт</div>
					<div className="day-name">Пт</div>
					<div className="day-name">Сб</div>
					<div className="day-name">Вс</div>
					{renderDays()}
				</div>

				<div className="tournament-calendar__venues">
					<MySelect
						id={'venue'}
						onChange={(option) => setSelectedVenue(option)}
						options={venuesOptions}
						value={selectedVenue}
						placeholder={t('Venue')}
						blueStyle
					/>
				</div>

				<div className="tournament-calendar__buttons">
					<RoundedButton onClick={showTournamentsForMonth} title={'Сохранить'} border />

					<RoundedButton onClick={resetList}  title={'Отменить'} background={'white'} border />
				</div>
			</div>
		</Popup>
	)
}

export default TournamentCalendar