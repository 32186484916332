import './RoundedArrowBlock.scss'
import { ReactComponent as ArrowRight } from '../../icons/arrow_right.svg'

function RoundedArrowBlock({ text, onClick }) {

	return (
		<div className="rounded-arrow-link-block" onClick={onClick}>
			<p>{text}</p>

			<ArrowRight className="rounded-arrow-link-block__arrow" />
		</div>
	)
}

export default RoundedArrowBlock