import MDEditor from '@uiw/react-md-editor'
import rehypeSanitize from 'rehype-sanitize'
import './MarkdownEditor.scss'

function MarkdownEditor({name, value, onChange, maxLength, placeholder, height, ...props}) {
	return (
		<div className={'markdown-editor'}>
			<label htmlFor={name}>{props.labelContent}</label>
			<MDEditor
				value={value}
				onChange={onChange}
				preview={'edit'}
				height={height}
				previewOptions={{
					rehypePlugins: [[rehypeSanitize]],
				}}
				textareaProps={{
					placeholder: placeholder,
					maxLength: maxLength,
				}}
			/>
		</div>
	)
}

export default MarkdownEditor