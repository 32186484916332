import { memo } from 'react'
import MDEditor from '@uiw/react-md-editor'
import rehypeSanitize from 'rehype-sanitize'

import './LabelBlock.scss'

function LabelBlock({ label, content, borderTop, borderBottom, paddingLeftRight, formattedText, isMarkdown }) {
	const renderContent = () => {
		if (isMarkdown) {
			return <MDEditor.Markdown source={content} className="label-block__content" previewOptions={{
				rehypePlugins: [[rehypeSanitize]],
			}} />
		}

		return formattedText ? (
			<pre className="label-block__content">{content}</pre>
		) : (
			<span className="label-block__content">{content}</span>
		)
	}

	return (
		<div
			className={`label-block ${borderBottom ? 'label-block--bottom' : ''} ${borderTop ? 'label-block--top' : ''}`}
			style={{ padding: paddingLeftRight ? '12px' : '12px 0' }}
		>
			<label className="label-block__label">{label}</label>
			{renderContent()}
		</div>
	)
}

export default memo(LabelBlock)