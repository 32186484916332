import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'

async function basicRequest(args, api, extraOptions) {
	const { getState } = api
	const state = getState()
	const authState = state.auth
	const { access } = authState

	const baseQuery = fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers) => {

			if (String(access).length > 25) {
				headers.set('authorization', 'Bearer ' + access)
			}

			return headers
		}
	})

	const response = await baseQuery(args, api, extraOptions)
	return response
}

export {
	basicRequest
}