import packageJson from '../../../package.json'
import { NODE_TYPE_IN, NODE_TYPE_MIDDLE } from '../../utils/constants'

export default function getSchedulePrintData(tournamentData, stage, initialStage, tableMatches) {
	const tournament = JSON.parse(sessionStorage.getItem('tournament'))

	let tableType
	let matchType
	const tables = []

	console.log(tableMatches)

	initialStage?.levels?.flatMap((level) =>
		level.groups?.filter((group) => Object.keys(group).length !== 0)
			.map((group) => {
				tableType = group.type

				const matches = group.matches.map((match) => {
					const matchingTableMatch = tableMatches.flatMap(tableMatch => tableMatch.matches)
						.find(matchqq => matchqq.matchUid === match.uid)

					return {
						...match,
						time_order_number: matchingTableMatch ? matchingTableMatch.timeOrderNumber : '',
					}
				})
				const playersTable = group.nodes
					.flatMap((node) => {
						let playerObject
						if (node.type === NODE_TYPE_IN || node.type === NODE_TYPE_MIDDLE) {
							if (node.double_uid === null && node.player_uid === null) {
								return null
							} else if (tournamentData.players) {
								playerObject = tournamentData.players.find((player) => player.uid === node.player_uid)
								matchType = 'singles'
							} else if (tournamentData.doubles) {
								playerObject = tournamentData.doubles.find((doublesPlayer) => doublesPlayer.uid === node.double_uid)
								matchType = 'doubles'
							}
							if (playerObject) {
								return {
									...playerObject,
									nodeUid: node?.uid,
								}
							}
						}

						return null
					}).filter((player) => player !== null)

				tables.push({
					levels: [
						{
							order_number: group.order_number,
							players: playersTable,
							matches: matches
						}
					]
				})
			})
	)

	const resultJSON = {
		version: packageJson.version,
		environment: window.location.hostname,
		tournament_name: tournamentData.name,
		description: tournament.start_date,
		stage: stage,
		page: 'schedule',
		tables: tables,
		match_type: Array.isArray(tournament?.doubles) ? 'doubles' : 'singles'
	}

	return resultJSON
}