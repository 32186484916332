import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import './ParticipantsOptions.scss'

import { TYPE_SINGLES } from '../../utils/constants'
import { setNoSortedParticipants, setOptionsOfDouble, setOptionsOfSingle, setParticipants, setUserChangedList } from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import Popup from '../../reusableComponents/Popup/Popup'
import { selectParticipants, selectTournament } from '../../redux/selectors'

function ParticipantsOption({ closeOptions }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {
		participants,
		optionsOfSingle,
		optionsOfDouble,
	} = useSelector(selectParticipants)
	const { type } = useSelector(selectTournament)
	function removeParticipantFromList() {
		const participant = type === TYPE_SINGLES ? optionsOfSingle : optionsOfDouble
		const updatedList = participants?.filter(p => participant.uid !== p?.uid)

		dispatch(setParticipants({ participants: updatedList }))
		dispatch(setNoSortedParticipants(updatedList))  // если после этого будет сортировка, то откатится к этому списку
		dispatch(setOptionsOfDouble({}))
		dispatch(setOptionsOfSingle({}))
		dispatch(setUserChangedList(true))
		closeOptions()
	}

	console.log(optionsOfSingle)

	return (
		<Popup title={'Опции участника'} close onClose={closeOptions}>
			<div className="participants__options">
				{/* {settings?.displayName && <p>Табличное имя</p>} */}

				{/* {settings?.displayName ?
				type === TYPE_SINGLES ?
					<Input
						value={optionsOfSingle.value}
						maxLength={8}
						type={'text'}
						placeholder={'Участник'}
						onChange={(e) => onChangeSingleNickname(e.target.value)}
					/>
					:
					<>
						<Input
							value={optionsOfDouble.value1}
							maxLength={8}
							type={'text'}
							placeholder={'Участник 1'}
							onChange={(e) => onChangeFirstNickname(e.target.value)}
						/>

						<Input
							value={optionsOfDouble.value2}
							maxLength={8}
							type={'text'}
							placeholder={'Участник 2'}
							onChange={(e) => onChangeSecondNickname(e.target.value)}
						/>
					</>
				:
				<p className="tournament-participants__options-text">Нет доступных опций</p>
			} */}

				{/* {settings?.displayName &&
				<div className="tournament-participants__warning-buttons">
					<RoundedButton
						title={'Сохранить'}
						background={'white'}
						border={true}
						onClick={saveParticipantOptions}
					/>

					<RoundedButton title={'Отменить'} onClick={closeOptions} />
				</div>
			} */}

				{type === TYPE_SINGLES ?
					<>
						<span className="participants__options-field">ФИО</span>
						<span className="participants__options-value">
							{optionsOfSingle.last_name}
							{' '}{optionsOfSingle.first_name || ''}
							{' '}{optionsOfSingle.middle_name || ''}
						</span>

						<span className="participants__options-field">Пол</span>
						<span className="participants__options-value">
							{t(optionsOfSingle.gender) || 'Не указан'}
						</span>

						<span className="participants__options-field">Балл</span>
						<span className="participants__options-value">
							{t(optionsOfSingle.strength) || 'Не указан'}
						</span>
					</>
					:
					<>
						<div className="participants__options-container">
							<span className="participants__options-field">ФИО</span>
							<span className="participants__options-value">
								{optionsOfDouble.player1.last_name}
								{' '}{optionsOfDouble.player1.first_name || ''}
								{' '}{optionsOfDouble.player1.middle_name || ''}
							</span>

							<span className="participants__options-field">Пол</span>
							<span className="participants__options-value">
								{t(optionsOfDouble.player1.gender) || 'Не указан'}
							</span>

							<span className="participants__options-field">Балл</span>
							<span className="participants__options-value">
								{t(optionsOfDouble.player1.strength) || 'Не указан'}
							</span>
						</div>

						<div className="participants__options-container">
							<span className="participants__options-field">ФИО</span>
							<span className="participants__options-value">
								{optionsOfDouble.player2.last_name}
								{' '}{optionsOfDouble.player2.first_name || ''}
								{' '}{optionsOfDouble.player2.middle_name || ''}
							</span>

							<span className="participants__options-field">Пол</span>
							<span className="participants__options-value">
								{t(optionsOfDouble.player2.gender) || 'Не указан'}
							</span>

							<span className="participants__options-field">Балл</span>
							<span className="participants__options-value">
								{t(optionsOfDouble.player2.strength) || 'Не указан'}
							</span>
						</div>
					</>
				}

				<button onClick={() => removeParticipantFromList()}>Удалить участника</button>
			</div>
		</Popup>
	)
}

export default memo(ParticipantsOption)