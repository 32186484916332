import { Routes, Route, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import './App.scss'
import Account from './views/Account/Account'
import Home from './views/Home/Home'
import Tournaments from './views/Tournaments/Tournaments'
import Tournament from './views/Tournament/Tournament'
import Categories from './views/Categories/Categories'
import Players from './views/Players/Players'
import Venues from './views/Venues/Venues'
import SettingsAccount from './views/SettingsAccount/SettingsAccount'
import Community from './views/Community/Community'
import Login from './views/Login/Login'
import Registration from './views/Registration/Registration'
import PasswordRecovery from './views/PasswordRecovery/PasswordRecovery'
import Info from './views/Info/Info'
import RequireAuth from './views/RequireAuth/RequireAuth'
import PlayerForm from './views/PlayerForm/PlayerForm'
import VenuesForm from './views/VenuesForm/VenuesForm'
import CategoryForm from './views/CategoryForm/CategoryForm'
import PopupError from './reusableComponents/PopupError/PopupError'
import Communities from './views/Communities/Communities'
import SplashScreen from './views/SplashScreen/SplashScreen'
import PasswordChange from './views/PasswordChange/PasswordChange'
import TournamentForm from './views/TournamentForm/TournamentForm'
import Missing from './views/Missing/Missing'
import { selectAuth } from './redux/selectors'
import { setIsGuest } from './redux/authService/authSlice'

function App() {
	const dispatch = useDispatch()
	const location = useLocation()
	const { isGuest } = useSelector(selectAuth)
	const error = useSelector((state) => { return state.error.error })

	// Определяем публичность турнира
	useEffect(() => {
		const pathParts = location.pathname.split('/')

		if (!pathParts[1].includes('t')) {
			if (isGuest) {
				dispatch(setIsGuest(false))
			}

			// url не относится к турниру
			return
		}

		const tournamentParams = pathParts[2]

		if (!tournamentParams) {
			if (isGuest) {
				dispatch(setIsGuest(false))
			}

			return
		}

		if (tournamentParams.includes('-')) {
			// есть публичный код турнира в url
			const guest = tournamentParams.split('-')[1]

			if (guest) {
				dispatch(setIsGuest(true))
			}
		} else {
			if (isGuest) {
				dispatch(setIsGuest(false))
			}
		}
	}, [location, isGuest])

	return (
		<div className="app">
			<Routes>
				<Route path="/auth" element={<SplashScreen />} />
				<Route path="/" element={<Home />} />

				<Route path="/t/:tournamentParams/*" element={<Tournament />} />

				<Route element={<RequireAuth />}>
					<Route path="/form/*" element={<TournamentForm />} />
					<Route path="/tournaments" element={<Tournaments />} />

					<Route path="/account" element={<Account />} />
					<Route path="/account/categories" element={<Categories />} />
					<Route path="/account/password" element={<PasswordChange />} />
					<Route path="/players" element={<Players />} />
					<Route path="/player/form" element={<PlayerForm />} />
					<Route path="/player/info/:uid" element={<PlayerForm />} />
					<Route path="/venues" element={<Venues />} />
					<Route path="/account/settings" element={<SettingsAccount />} />
					<Route path="/community" element={<Community />} />
					<Route path="/communities" element={<Communities />} />
					<Route path="/venues/form" element={<VenuesForm />} />
					<Route path="/venues/form/:uid" element={<VenuesForm />} />
					<Route path="/account/category/form" element={<CategoryForm />} />
				</Route>

				<Route path="/info" element={<Info />} />
				<Route path="/login" element={<Login />} />
				<Route path="/registration" element={<Registration />} />

				<Route path="/password-recovery" element={<PasswordRecovery />} />
				<Route path="*" element={<Missing />} />
			</Routes>

			{
				error &&
					<PopupError error={error} />
			}
		</div>
	)
}

export default App