function sortByRanking(group) {

	if (!group?.results[0]?.ranking) {
		return { results: group.results, nodes: group.nodes }
	}

	const results = [...group.results]
	const sortedResults = results.sort((a, b) => a.ranking - b.ranking)

	const nodes = [...group.nodes]
	const sortedNodes = nodes.sort((a, b) => {
		const indexA = sortedResults.findIndex(item => item.node_uid === a.uid)
		const indexB = sortedResults.findIndex(item => item.node_uid === b.uid)
		return indexA - indexB
	})

	return { results: sortedResults, nodes: sortedNodes }
}

function groupLengthCheck(stage) {
	const groupLengthResult = stage?.levels?.map((level, levelIndex) => {
		return level.groups.length > 1
	})

	return groupLengthResult?.some(element => element === true)
}

export {
	groupLengthCheck,
	sortByRanking
}