import { Draggable } from 'react-beautiful-dnd'
import { memo, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import './GroupPlayer.scss'
import { TYPE_DOUBLES, TYPE_SINGLES, GROUP_TYPE_OLYMPIC_PLUS } from '../../../utils/constants'
import { selectTournament, selectDragState, selectTournamentOptions } from '../../../redux/selectors'
import { ReactComponent as DragIcon } from '../../../icons/drag.svg'
import { ReactComponent as Blocked } from '../../../icons/cross-blocked.svg'
import CloneComponentDnd from '../../../reusableComponents/CloneComponentDnd/CloneComponentDnd'
import { setShowForbiddenMessage } from '../../../redux/stageService/stageSlice'
import { nodeInGame } from '../../../utils/functions2'

function GroupPlayer({ position, group, node, handleName }) {
	const dispatch = useDispatch()
	const { type } = useSelector(selectTournament)
	const { destination, source, draggableId } = useSelector(selectDragState)
	const { stage: options = {} } = useSelector(selectTournamentOptions)
	const [levelIndex, groupIndex, index] = position
	const droppableId = `${levelIndex}-${groupIndex}`
	const { type: groupType, nodes } = group

	const inGame = useMemo(() => {
		return nodeInGame(group, node)
	}, [group, node])

	const playerClone = useMemo(() => {
		if (!source) {
			return null
		}

		const group = document.querySelector(
			`[data-rbd-droppable-id = '${source?.droppableId}']`
		)

		const targetElement = group.querySelector(
			`[data-rbd-draggable-id='${draggableId}']`
		)

		if (group.contains(targetElement)) {
			const clone = targetElement.cloneNode(true)
			clone.style.opacity = 0.3

			return clone
		}
	}, [draggableId, source])

	const defineStyles = useCallback((isDragging, provided) => {
		const nodesCount = nodes?.length
		const endDroppableId = destination?.droppableId
		const dropIndex = destination?.index
		const isDropItem = endDroppableId === droppableId && dropIndex === index ? true : false
		const styleTransform = provided.draggableProps.style.transform

		let style = {
			...provided.draggableProps.style,
			transform: !isDragging ? null : styleTransform,
			height: type === TYPE_DOUBLES ? '60px' : '48px',
		}

		if (groupType === GROUP_TYPE_OLYMPIC_PLUS && index % 2 === 1 && nodesCount - 1 > index && !isDragging) {
			style = {
				...style,
				borderBottom: '1px solid var(--palette-live-grey-7)'
			}
		} else if (nodesCount - 1 > index && !isDragging) {
			style = {
				...style,
				borderBottom: '1px solid var(--palette-live-grey-11)'
			}
		}

		if (isDragging) {
			return {
				...style,
				backgroundColor: 'var(--palette-live-grey-8)',
				webkitBoxShadow: '0px 7px 11px -5px rgba(34, 60, 80, 0.2)',
				MozBoxShadow: '0px 7px 11px -5px rgba(34, 60, 80, 0.2)',
				boxShadow: '0px 7px 11px -5px rgba(19, 35, 47, 0.2)',
				border: 'none'
			}
		} else if (isDropItem && !node?.disabled && !inGame) {
			return {
				...style,
				backgroundColor: 'var(--palette-live-blue-3)',
				borderBottomLeftRadius: 'none',
				borderBottomRightRadius: dropIndex === nodesCount - 1 ? '16px' : 'none'
			}
		} else {
			return {
				...style,
				backgroundColor: 'transparent'
			}
		}
	}, [destination, node, groupType, nodes, index, droppableId, type, inGame])

	function handleUserAction() {
		if (inGame) {
			dispatch(setShowForbiddenMessage(true))
			return
		}
	}

	return (
		<>
			{
				playerClone && playerClone.getAttribute('data-rbd-draggable-id') === node?.nodeUid &&
					<CloneComponentDnd htmlContent={playerClone.outerHTML} />
			}

			{
				type === TYPE_SINGLES &&
					<Draggable
						draggableId={node?.nodeUid}
						index={index}
						isDragDisabled={node?.disabled || !options?.groupActions?.sortParticpants || inGame ? true : false}
					>
						{
							(provided, snapshot) => (
								<div
									key={index}
									className={`group-player__single ${!inGame ? 'active' : ''}`}
									ref={provided.innerRef}
									{...provided.draggableProps}
									style={
										defineStyles(snapshot.isDragging, provided,)
									}
									onClick={inGame ? () => dispatch(setShowForbiddenMessage(true)) : () => handleName(index, node)}
									onMouseDown={handleUserAction}
								>
									<div className="group-player__single-wrapper">
										{((node.name || node?.participant?.formedName)
												|| group.type !== GROUP_TYPE_OLYMPIC_PLUS) &&
											<p className="group-player__single-index">
												{ index + 1 }
											</p>
										}

										<p className="group-player__single-name">
											{
												!node.name && !node?.participant?.formedName && !node?.disabled ?
													<span className="name-line"></span>
													: node?.disabled ?
														<Blocked />
														: node?.participant?.lastName ?
															`${node?.participant?.formedName} ${node?.participant?.firstName || ''}`
															:
															`${node.name}`
											}
										</p>

										<p className="group-player__single-rating">
											{ node.rating ? `[${node.rating}]` : '' }
										</p>
									</div>

									<div
										className="group-player__buttons"
										{...provided.dragHandleProps}
									>
										{
											options?.groupActions?.sortParticpants && !node?.disabled && !inGame &&
												<DragIcon className="drag-icon" />
										}
									</div>
								</div>
							)
						}
					</Draggable>
			}

			{
				type === TYPE_DOUBLES &&
					<Draggable
						draggableId={node?.nodeUid}
						index={index}
						isDragDisabled={node.disabled || !options?.groupActions?.sortParticpants || inGame  ? true : false}
					>
						{
							(provided, snapshot) => (
								<div
									key={index}
									className={`group-player__double ${!inGame ? 'active' : ''}`}
									ref={provided.innerRef}
									{...provided.draggableProps}
									style={
										defineStyles(snapshot.isDragging, provided)
									}
								>
									{
										node.disabled ?
											<Blocked />
											:
											!node?.doubleUid ?
												<div
													className="group-player__double-wrapper"
													onClick={inGame ? () => dispatch(setShowForbiddenMessage(true)) : () => handleName(index, node)}
													onMouseDown={handleUserAction}
												>
													{((node.name || node?.participant.firstPlayerName)
													|| group.type !== GROUP_TYPE_OLYMPIC_PLUS) &&
														<p className="group-player__double-index">
															{ index + 1 }
														</p>
													}

													<p className="group-player__double-notplaying">
														{
															!node.name ?
																<span className="name-line"></span>
																:
																`${node.name}`
														}
													</p>
												</div>
												:
												<div
													className="group-player__double-wrapper"
													onClick={inGame ? () => dispatch(setShowForbiddenMessage(true)) : () => handleName(index, node)}
													onMouseDown={handleUserAction}
												>
													{((node?.name || node?.participant.firstPlayerName) || group?.type !== GROUP_TYPE_OLYMPIC_PLUS) &&
														<p className="group-player__double-index">
															{ index + 1 }
														</p>
													}

													<div>
														<p className="group-player__double-name">
															{ node?.participant.firstPlayerName } { node?.participant.firstName1 }
														</p>

														<p className="group-player__double-name">
															{ node?.participant.secondPlayerName } { node?.participant.firstName2 }
														</p> 
													</div>

													<p className="group-player__double-rating">
														{node?.rating ? `[${node?.rating}]` : ''}
													</p>
												</div>
									}

									<div
										className="group-player__buttons"
										{...provided.dragHandleProps}
									>
										{options?.groupActions?.sortParticpants && !node?.disabled && !inGame &&
											<DragIcon className="drag-icon" />
										}
									</div>
								</div>
							)
						}
					</Draggable>
			}
		</>
	)
}

export default memo(GroupPlayer)