import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'

import './OlympicPlusGrid.scss'
import { setMatchData, setMatchSession } from '../../redux/matchService/matchSlice'
import { addFirstSet } from '../../views/Match/externalFunctions'
import { selectAuth, selectTournament, selectTournamentOptions } from '../../redux/selectors'
import { TYPE_SINGLES, FINAL_COLUMN_NAME, RESULT_COLUMN_NAME, TYPE_DOUBLES } from '../../utils/constants'
import { generateGroupOrLevelsName, getParameters } from '../../utils/functions'
import { ReactComponent as Ball } from '../../icons/tennis_ball.svg'
import StageGroupIcon from '../../reusableComponents/StageGroupIcon/StageGroupIcon'
import Tooltip from '../../reusableComponents/Tooltip/Tooltip'
import { getFormedNameWithEllipses } from '../../utils/functions2'

import { formGrid } from './externalFunctions'

function OlympicPlusGrid({ group, gridNameColumnWidth, setScroll }) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useDispatch()
	const { tournamentParams, stageNumber } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const { authorized, isGuest } = useSelector(selectAuth)
	const { type, players, doubles } = useSelector(selectTournament)
	const { stage: options = {} } = useSelector(selectTournamentOptions)

	const grid = useMemo(() => {
		return formGrid(group)
	}, [group])

	const participantsMap = useMemo(() => {
		return new Map((players || doubles)?.map(item => {
			const player = getFormedNameWithEllipses(players, doubles, item)
			return [item.uid, player]
		}))
	}, [players, doubles])

	function formMatch(match) {
		const { matchData, nodeOne, nodeTwo } = match

		const uidOne = nodeOne?.player_uid || nodeOne?.double_uid
		const uidTwo = nodeTwo?.player_uid || nodeTwo?.double_uid

		let updatedMatch = addFirstSet(matchData, options)

		if (type === TYPE_SINGLES) {
			const singleOne = participantsMap.get(uidOne)?.formedName
			const singleTwo = participantsMap.get(uidTwo)?.formedName

			updatedMatch = {
				...updatedMatch,
				side1: {
					namePlayer: singleOne,
					nodeUid: updatedMatch?.side1_uid
				},
				side2: {
					namePlayer: singleTwo,
					nodeUid: updatedMatch?.side2_uid
				},
				uid: updatedMatch?.uid
			}
		} else {
			const doubleOne = {
				firstPlayer: participantsMap.get(uidOne)?.player1.formedName,
				secondPlayer: participantsMap.get(uidOne)?.player2.formedName
			}
			const doubleTwo = {
				firstPlayer: participantsMap.get(uidTwo)?.player1.formedName,
				secondPlayer: participantsMap.get(uidTwo)?.player2.formedName
			}

			updatedMatch = {
				...updatedMatch,
				side1: {
					namePlayer1: doubleOne.firstPlayer,
					namePlayer2: doubleOne.secondPlayer,
					nodeUid: updatedMatch.side1_uid
				},
				side2: {
					namePlayer1: doubleTwo.firstPlayer,
					namePlayer2: doubleTwo.secondPlayer,
					nodeUid: updatedMatch.side2_uid
				},
				uid: updatedMatch?.uid
			}
		}

		return updatedMatch
	}

	function goToMatch(cell) {
		const { matchData, nodeOne, nodeTwo } = cell

		const matchNotExists = !matchData || (
			(type === TYPE_SINGLES && (!nodeOne?.player_uid || !nodeTwo?.player_uid))
			|| (type === TYPE_DOUBLES && (!nodeOne?.double_uid || !nodeTwo?.double_uid))
		)

		if (matchNotExists || !authorized || isGuest) return

		setScroll()

		const updatedMatch = formMatch(cell)
		updatedMatch.pathBack = location.pathname
		dispatch(setMatchData(updatedMatch))
		dispatch(setMatchSession(updatedMatch))
		navigate('/t/' + tournamentUid + '/draws/' + stageNumber + '/match')
	}

	function getLoser(match) {
		const noLoser = null

		if (!match.match || !match.matchData.is_finished) return noLoser

		const { matchData } = match
		const { sets, side1_uid, side2_uid } = matchData

		let score1 = 0
		let score2 = 0

		sets.forEach(item => {
			const pointsOne = item.score1 + item.tie_break_score1
			const pointsTwo = item.score2 + item.tie_break_score2

			if (pointsOne > pointsTwo) {
				score1++
			} else if (pointsOne < pointsTwo) {
				score2++
			}
		})

		if (score1 > score2) {
			return side2_uid
		} else if (score1 < score2) {
			return side1_uid
		} else {
			return noLoser
		}
	}

	function getSidesLayout(match) {
		const { nodeOne, nodeTwo } = match
		const uidOne = nodeOne?.player_uid || nodeOne?.double_uid
		const uidTwo = nodeTwo?.player_uid || nodeTwo?.double_uid
		const insteadOfNameOne = nodeOne?.name || <span className="empty-player"></span>
		const insteadOfNameTwo = nodeTwo?.name || <span className="empty-player"></span>

		const isLoser = getLoser(match)

		if (type === TYPE_SINGLES) {
			const singleOne = participantsMap.get(uidOne)
			const singleTwo = participantsMap.get(uidTwo)

			let sideOneClass = 'olympicplus-grid__match-participant'
			let sideTwoClass = 'olympicplus-grid__match-participant'

			if (isLoser === nodeOne?.uid) {
				sideOneClass = 'olympicplus-grid__match-participant olympicplus-grid__match-loser'
			} else if (isLoser === nodeTwo?.uid) {
				sideTwoClass = 'olympicplus-grid__match-participant olympicplus-grid__match-loser'
			}

			return (
				<div className="olympicplus-grid__match-sides">
					<Tooltip
						content={`${singleOne?.last_name} ${singleOne?.first_name || ''} ${singleOne?.middle_name || ''}`}
						disable={singleOne?.formedName ? false : true}
					>
						<p className={sideOneClass}>
							{ singleOne?.formedName || insteadOfNameOne }
						</p>
					</Tooltip>

					<Tooltip
						content={`${singleTwo?.last_name} ${singleTwo?.first_name || ''} ${singleTwo?.middle_name || ''}`}
						disable={singleTwo?.formedName ? false : true}
					>
						<p className={sideTwoClass}>
							{ singleTwo?.formedName || insteadOfNameTwo }
						</p>
					</Tooltip>
				</div>
			)
		} else {
			const doubleOne = {
				firstPlayer: participantsMap.get(uidOne)?.player1,
				secondPlayer: participantsMap.get(uidOne)?.player2
			}
			const doubleTwo = {
				firstPlayer: participantsMap.get(uidTwo)?.player1,
				secondPlayer: participantsMap.get(uidTwo)?.player2
			}

			let sideOneClass = 'olympicplus-grid__match-dobles'
			let sideTwoClass = 'olympicplus-grid__match-dobles'

			if (isLoser === nodeOne?.uid) {
				sideOneClass = 'olympicplus-grid__match-dobles olympicplus-grid__match-loser'
			} else if (isLoser === nodeTwo?.uid) {
				sideTwoClass = 'olympicplus-grid__match-dobles olympicplus-grid__match-loser'
			}

			return (
				<div className="olympicplus-grid__match-sides">
					<div className={sideOneClass}>
						{
							uidOne ?
								<>
									<Tooltip
										content={`${doubleOne.firstPlayer?.last_name} ${doubleOne.firstPlayer?.first_name || ''} ${doubleOne.firstPlayer?.middle_name || ''}`}
										disable={doubleOne.firstPlayer?.formedName ? false : true}
									>
										<p className="olympicplus-grid__match-double-player">
											{ doubleOne.firstPlayer?.formedName }
										</p>
									</Tooltip>

									<Tooltip
										content={`${doubleOne.secondPlayer?.last_name} ${doubleOne.secondPlayer?.first_name || ''} ${doubleOne.secondPlayer?.middle_name || ''}`}
										disable={doubleOne.secondPlayer?.formedName ? false : true}
									>
										<p className="olympicplus-grid__match-double-player">
											{ doubleOne.secondPlayer?.formedName }
										</p>
									</Tooltip>
								</>
								:
								<p className="olympicplus-grid__match-notplaying">
									{ insteadOfNameOne }
								</p>
						}
					</div>

					<div className={sideTwoClass}>
						{
							uidTwo ?
								<>
									<Tooltip
										content={`${doubleTwo.firstPlayer?.last_name} ${doubleTwo.firstPlayer?.first_name || ''} ${doubleTwo.firstPlayer?.middle_name || ''}`}
										disable={doubleTwo.firstPlayer?.formedName ? false : true}
									>
										<p className="olympicplus-grid__match-double-player">
											{ doubleTwo.firstPlayer?.formedName }
										</p>
									</Tooltip>

									<Tooltip
										content={`${doubleTwo.secondPlayer?.last_name} ${doubleTwo.secondPlayer?.first_name || ''} ${doubleTwo.secondPlayer?.middle_name || ''}`}
										disable={doubleTwo.secondPlayer?.formedName ? false : true}
									>
										<p className="olympicplus-grid__match-double-player">
											{ doubleTwo.secondPlayer?.formedName }
										</p>
									</Tooltip>
								</>
								:
								<p className="olympicplus-grid__match-notplaying">
									{ insteadOfNameTwo }
								</p>
						}
					</div>
				</div>
			)
		}
	}

	function getSets(match) {
		const matchData = match?.matchData

		if (matchData?.sets.length > 0) {
			const sets = matchData?.sets

			return sets.map((set, index) => {
				return (
					<div
						key={index}
						className="olympicplus-grid__match-set"
					>
						<div className="olympicplus-grid__match-set-points">
							<p className="olympicplus-grid__match-set-score">
								{ set.score1 }
							</p>

							<sup className="olympicplus-grid__match-set-tiebreak">
								{ set.tie_break_score1 }
							</sup>
						</div>

						<div className="olympicplus-grid__match-set-points">
							<p className="olympicplus-grid__match-set-score">
								{ set.score2 }
							</p>

							<sup className="olympicplus-grid__match-set-tiebreak">
								{ set.tie_break_score2 }
							</sup>
						</div>
					</div>
				)
			})
		}
	}

	function getParticipantForResult(node) {
		const uid = node?.player_uid || node?.double_uid
		const insteadOfName = node?.name || ''
		const participant = participantsMap.get(uid)

		if (type === TYPE_SINGLES) {
			return (
				<>
					{
						uid ?
							<p className="olympicplus-grid__result-name">
								{ participant?.formedName }
							</p>
							:
							<p className="olympicplus-grid__result-name">
								{ insteadOfName }
							</p>
					}
				</>
			)
		} else {
			return (
				<>
					{
						uid ?
							<div className="olympicplus-grid__result-double">
								<p className="olympicplus-grid__result-name">
									{ participant.player1?.formedName }
								</p>

								<p className="olympicplus-grid__result-name">
									{ participant.player2?.formedName }
								</p>
							</div>
							:
							<p className="olympicplus-grid__result-name">
								{ insteadOfName }
							</p>
					}
				</>
			)
		}
	}

	function matchStyle(column, columnIndex, cell) {
		const matchNotExists = !cell?.matchData || (
			(type === TYPE_SINGLES && (!cell?.nodeOne?.player_uid || !cell?.nodeTwo?.player_uid))
			|| (type === TYPE_DOUBLES && (!cell?.nodeOne?.double_uid || !cell?.nodeTwo?.double_uid))
		)

		let height = ''

		if (type === TYPE_SINGLES) {
			height = '65px'
		} else {
			height = '120px'
		}

		let style = {
			height,
			cursor: !matchNotExists && authorized && !isGuest ? 'pointer' : ''
		}

		if (columnIndex > 0 && column.roundName !== RESULT_COLUMN_NAME) {
			style = {
				...style,
				backgroundColor: cell?.winner ? 'var(--palette-live-bg-0-white)' : 'var(--palette-live-grey-8)'
			}
		} else if (columnIndex === 0) {
			style = {
				...style,
				backgroundColor: 'var(--palette-live-bg-0-white)'
			}
		}

		return style
	}

	const resultBlockStyle = useCallback((cellIndex, matches) => {
		let height = ''

		if (type === TYPE_SINGLES) {
			height = matches?.length > 1 ? '32px' : '65px'
		} else {
			height = matches?.length > 1 ? '60px' : '120px'
		}

		return { // тута
			backgroundColor: (cellIndex + 1) % 2 === 0
				? 'var(--palette-live-grey-8)'
				: 'var(--palette-live-bg-0-white)',
			height
		}
	}, [type])

	return (
		<div className="olympicplus-grid">
			<div className="olympicplus-grid__table-wrapper">
				{
					grid.map((column, columnIndex) => (
						<div
							key={columnIndex}
							className="olympicplus-grid__table-container"
						>
							<div className="olympicplus-grid__table">
								<div key={columnIndex} className="olympicplus-grid__row-header">
									<h5 className="olympicplus-grid__header">
										<div className="olympicplus-grid__header-container">
											{
												columnIndex === 0 &&
													<div className="olympicplus-grid__header-name">
														{ generateGroupOrLevelsName(group.order_number - 1) }

														<StageGroupIcon type={group?.type || ''}/>
													</div>
											}

											<div className="olympicplus-grid__header-order">
												{
													column.roundName !== RESULT_COLUMN_NAME ?
														column.roundName > 1 ?
															`1/${column.roundName}`
															:
															t(FINAL_COLUMN_NAME)
														:
														t(column.roundName)
												}
											</div>
										</div>
									</h5>
								</div>

								{
									column.matches.map((cell, cellIndex) => (
										<div
											key={cellIndex}
											className="olympicplus-grid__row"
										>
											{
												column.roundName !== RESULT_COLUMN_NAME ?
													<>
														<div
															className="olympicplus-grid__cell"
															style={matchStyle(column, columnIndex, cell)}
															// onClick={() => goToMatch(cell)}
														>
															<div className="olympicplus-grid__match">
																<div
																	className="olympicplus-grid__match-container"
																	style={{ minWidth: gridNameColumnWidth + 15 + 'px'}}
																>
																	<div
																		className="olympicplus-grid__match-order"
																		onClick={() => goToMatch(cell)}
																	>
																		{/* Индекс */}
																		<p className="olympicplus-grid__match-number">
																			{ cell?.matchNumber } 
																		</p>

																		{
																			!cell?.matchData?.is_finished &&
																				cell?.matchData?.sets.length > 0 &&
																					<Ball className="olympicplus-grid__match-ball" />
																		}
																	</div>

																	{ getSidesLayout(cell) }

																	<div
																		className="olympicplus-grid__match-sets"
																		style={cell?.matchData?.sets?.length > 0 ? {} : { minWidth: '36px'}} // а иначе ломаются стили когда сетов много, не влезают в клетку
																		onClick={() => goToMatch(cell)}
																	>
																		{ getSets(cell) }
																	</div>
																</div>
															</div>
														</div>
													</>
													:
													<div
														className="olympicplus-grid__cell olympicplus-grid__result-container"
														style={resultBlockStyle(cellIndex, column.matches)}
													>
														<div className="olympicplus-grid__result">
															{ getParticipantForResult(cell) }

															<p className="olympicplus-grid__result-order">
																{ cellIndex + 1 }
															</p>
														</div>
													</div>
											}
										</div>
									))
								}
							</div>
						</div>
					))
				}
			</div>
		</div>
	)
}

export default OlympicPlusGrid