import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import './Tournaments.scss'
import { useGetCommunityQuery } from '../../redux/communityService/communityApiSlice'
import Header from '../../reusableComponents/Header/Header'
import TournamentsList from '../../extendedComponents/TournamentsList/TournamentsList'
import AddButton from '../../reusableComponents/AddButton/AddButton'
import { ReactComponent as RocketsIcon } from '../../icons/rokets.svg'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import Plug from '../../reusableComponents/Plug/Plug'
import Loader from '../../reusableComponents/Loader/Loader'

import { selectCommunity } from '../../redux/selectors'
import {
	setCurrentStageNumber,
	setImmutableProcessedLevels,
	setInitialStage,
	setPlayersList,
	setProcessedLevelsForBuildMode,
	setProcessedStage,
	setShowPrestagerating
} from '../../redux/stageService/stageSlice'
import { setTournamentData } from '../../redux/singleTournamentService/singleTournamentSlice'
import { setCommunityPlayers, setInitPlayers } from '../../redux/playersService/playersSlice'
import { setNoSortedParticipants, setParticipants } from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import { FULFILLED } from '../../utils/constants'
import TournamentCalendar from '../../extendedComponents/TournamentCalendar/TournamentCalendar'

function Tournaments() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { communityUid, communityName } = useSelector(selectCommunity)
	const tourRef = useRef()
	const listRef = useRef()
	const [tournaments, setTournaments] = useState([])
	const [tournamentsMap, setTournamentsMap] = useState(new Map())
	const [openedCalendar, setOpenedCalendar] = useState(false)

	const { data = {}, status, error } = useGetCommunityQuery(
		{ community_uid: communityUid }, { skip: !communityUid, refetchOnMountOrArgChange: true }
	)

	useEffect(() => {
		dispatch(setCurrentStageNumber(1))
		dispatch(setTournamentData({}))
		dispatch(setProcessedStage({}))
		dispatch(setInitialStage({}))
		dispatch(setProcessedLevelsForBuildMode([]))
		dispatch(setImmutableProcessedLevels([]))
		dispatch(setPlayersList([]))
		dispatch(setShowPrestagerating(null))
		dispatch(setNoSortedParticipants([]))
		dispatch(setParticipants([]))
		dispatch(setCommunityPlayers([]))
		dispatch(setInitPlayers([]))
		sessionStorage.clear()
	}, [])

	useEffect(() => {
		if (Object.keys(data).length !== 0) {
			setTournaments(data?.tournaments)

			// const toursMap = new Map()

			// for (const item of data?.tournaments) {
			// 	const year = new Date(item.start_date).getFullYear()
			// 	const month = new Date(item.start_date).getMonth()
			// 	const day = new Date(item.start_date).getDate()
			// 	const toursOfYear = toursMap.get(year)

			// 	if (!toursOfYear) {
			// 		const toursOfMonths = new Map()
			// 		const daysMap = new Map()
			// 		daysMap.set(day, [item])
			// 		toursOfMonths.set(month, daysMap)
			// 		toursMap.set(year, toursOfMonths)
			// 	} else {
			// 		const toursOfMonths = toursOfYear.get(month)

			// 		if (toursOfMonths) {
			// 			// if (year === 2025 && month === 9) {
			// 			// 	console.log(item)
			// 			// }
			// 			const toursOfDay = toursOfMonths.get(day)

			// 			if (toursOfDay) {
			// 				if (year === 2025 && month === 9) {
			// 					console.log(toursOfDay)
			// 				}
							
			// 				toursOfDay.push(item)
			// 			} else {
			// 				const daysMap = new Map()
			// 				daysMap.set(day, [item])
			// 				toursOfMonths.set(month, daysMap)
			// 				toursMap.set(year, toursOfMonths)
			// 			}
			// 		} else {
			// 			const toursOfMonths = new Map()
			// 			const daysMap = new Map()
			// 			daysMap.set(day, [item])
			// 			toursOfMonths.set(month, daysMap)
			// 			toursMap.set(year, toursOfMonths)
			// 		}
			// 	}
			// }

			const toursMap = new Map()

			for (const item of data?.tournaments) {
				const year = new Date(item.start_date).getFullYear()
				const month = new Date(item.start_date).getMonth()
				const day = new Date(item.start_date).getDate()
				const toursOfYear = toursMap.get(year)

				if (!toursOfYear) {
					// Если для года нет записей, создаём новый Map для месяцев и дней
					const toursOfMonths = new Map()
					const daysMap = new Map()
					daysMap.set(day, [item])
					toursOfMonths.set(month, daysMap)
					toursMap.set(year, toursOfMonths)
				} else {
					const toursOfMonths = toursOfYear.get(month)

					if (toursOfMonths) {
						const toursOfDay = toursOfMonths.get(day)

						if (toursOfDay) {
							// Если есть записи на день, добавляем тур
							toursOfDay.push(item)
						} else {
							// Если нет записей на день, создаём новый список
							toursOfMonths.set(day, [item])
						}
					} else {
						// Если нет записей на месяц, создаём новый Map для дней
						const toursOfMonths = new Map()
						toursOfMonths.set(day, [item])
						toursOfYear.set(month, toursOfMonths)
					}
				}
			}

			setTournamentsMap(toursMap)
		}
	}, [data, dispatch])

	if (error) { console.log(error) }

	return (
		<>
			<Header
				subtitle={t('Tournaments')}
				communityName={communityName}
				border
			/>

			<main ref={tourRef} className="tournaments">
				{
					status !== FULFILLED ?
						<Loader />
						:
						<>
							{openedCalendar &&
								<TournamentCalendar
									venues={data.venues}
									tournamentsMap={tournamentsMap}
									onClose={setOpenedCalendar}
									setTournaments={setTournaments}
									tournaments={data?.tournaments}
								/>
							}

							<button
								onClick={() => setOpenedCalendar(true)}
								className="tournaments__calendar-button"
							>
								Календарь
							</button>
							{
								tournaments?.length < 1 ?
									<Plug
										icon={<RocketsIcon className="icon" />}
										title={t('Create tournament')}
										description={t('Click on the plus for creating a tournament')}
									/>
									:
									<TournamentsList
										list={tournaments}
										categories={data.categories}
										venues={data.venues}
										managers={data.managers}
										listRef={listRef}
									/>
							}

							<AddButton
								to={'/form/'}
							/>
						</>
				}
			</main>

			<BottomMenu />
		</>
	)
}

export default Tournaments