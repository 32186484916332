import { memo } from 'react'

import './TournamentBlock.scss'
import { ReactComponent as ArrowBackIcon } from '../../icons/arrow_back.svg'

function formatDate(inputDate) {
	const DAYSOFWEEK = ['Вск', 'Пнд', 'Втр', 'Срд', 'Чтв', 'Птн', 'Сбт']
	const MONTHS = ['ЯНВ', 'ФЕВ', 'МАР', 'АПР', 'МАЙ', 'ИЮН', 'ИЮЛ', 'АВГ', 'СЕН', 'ОКТ', 'НОЯ', 'ДЕК']

	const dateObj = new Date(inputDate)
	
	const dayOfWeek = DAYSOFWEEK[dateObj.getDay()]
	const dayOfMonth = dateObj.getDate()
	const month = MONTHS[dateObj.getMonth()]

	return [dayOfWeek, dayOfMonth, month]
}

function TournamentBlock({
	onClick,
	date,
	tournamentName,
	participantsCount,
	gender,
	category,
	status,
	type,
	venues,
	tier,
	createdBy
}) {
	return (
		<div
			className="tournaments-block"
			onClick={onClick}
		>
			<div className="tournaments-block__date">
				<p className="tournaments-date__element">
					{ formatDate(date)[0] }
				</p>
					
				<p className="tournaments-date__element">
					{ formatDate(date)[1] }
				</p>
					
				<p className="tournaments-date__element">
					{ formatDate(date)[2] }
				</p>
			</div>

			<div className="tournaments-block__data">
				<p className="tournaments-data__name">
					{ tournamentName }
				</p>

				<p className="tournaments-data__description">
					{/* {
						tier &&
							`T${tier.charAt(tier.length - 1)} | `
					} */}

					{
						participantsCount &&
							`${participantsCount}`
					}

					{/* {
						gender &&
							` | ${gender}`
					} */}

					{
						category &&
							` | ${category}`
					}

					{
						type &&
							` | ${type}`
					}

					{
						createdBy &&
							` | ${createdBy}`
					}
				</p>

				<p className="tournaments-data__addres">
					{ venues }
				</p>
			</div>

			<div className="tournaments-block__bubble">
				{
					status !== 'not_yet_started' && status !== 'finished' &&
						<div className="tournaments-bubble"></div>
				}
			</div>

			<div className="tournaments-block__icon">
				<ArrowBackIcon className="arrow-back__icon" />
			</div>
		</div>
	)
}

export default memo(TournamentBlock)