import packageJson from '../../../package.json'
import { NODE_TYPE_IN} from '../../utils/constants'
import { formGrid } from '../../extendedComponents/OlympicPlusGrid/externalFunctions.js'

export default function getDrawsPrintData(tournamentData, stage, initialStage) {
	const tournament = JSON.parse(sessionStorage.getItem('tournament'))

	let tableType
	let matchType
	const tables = []

	initialStage?.levels?.flatMap((level, levelIndex) =>
		level.groups?.filter((group) => Object.keys(group).length !== 0)
			.map((group, groupIndex) => {
				tableType = group.type

				let tableLevel = `${levelIndex + 1}`
				if (level.groups.length > 1) {
					tableLevel = groupIndex === 0 ? `${tableLevel}` : `${tableLevel}_n`
				}

				if (tableType === 'olympic_plus') {
					const olympicPlusFormGrid = formGrid(group)

					const playerMap = {}
					if (tournamentData.players && Array.isArray(tournamentData.players)) {
						tournamentData.players.forEach(player => {
							playerMap[player.uid] = player.display_name || player.formedName || ''
						})
					}

					const doublesMap = {}
					if (tournamentData.doubles && Array.isArray(tournamentData.doubles)) {
						tournamentData.doubles.forEach(team => {
							const player1Name = team.player1 ? (team.player1.display_name || team.player1.formedName || '') : ''
							const player2Name = team.player2 ? (team.player2.display_name || team.player2.formedName || '') : ''

							doublesMap[team.uid] = {
								player1: player1Name,
								player2: player2Name
							}
						})
					}

					function updateNode(node) {
						if (!node) return node

						if (node.player_uid) {
							matchType = 'singles'

							const playerName = playerMap[node.player_uid]

							if (playerName) {
								return { ...node, name: playerName }
							}
						}
						else if (node.double_uid) {
							matchType = 'doubles'

							const teamName = doublesMap[node.double_uid]

							if (teamName) {
								return { ...node, name: teamName }
							}
						}

						return node
					}

					const updatedOlympicPlusFormGrid = olympicPlusFormGrid.map(item => {
						if (item.matches && Array.isArray(item.matches)) {
							if (item.roundName === 'Result') {
								const resultMatches = []

								for (let i = 0; i < item.matches.length; i += 2) {
									const firstMatch = item.matches[i]
									const secondMatch = item.matches[i + 1]

									let updatedNodeOne = null
									let updatedNodeTwo = null
									
									if (firstMatch.player_uid) {
										updatedNodeOne = updateNode({ player_uid: firstMatch.player_uid })
										updatedNodeTwo = updateNode({ player_uid: secondMatch ? secondMatch.player_uid : null })
									}
									else {
										updatedNodeOne = updateNode({ double_uid: firstMatch.double_uid })
										updatedNodeTwo = updateNode({ double_uid: secondMatch ? secondMatch.double_uid : null })

										if (!firstMatch.double_uid && !secondMatch.double_uid) {
											updatedNodeOne.name = firstMatch.name
											updatedNodeTwo.name = secondMatch.name
										}
									}

									resultMatches.push({
										...firstMatch,
										nodeOne: updatedNodeOne,
										nodeTwo: updatedNodeTwo
									})
								}

								return { ...item, matches: resultMatches }
							} else {
								const updatedMatches = item.matches.map(match => ({
									...match,
									nodeOne: updateNode(match.nodeOne),
									nodeTwo: updateNode(match.nodeTwo)
								}))

								return { ...item, matches: updatedMatches }
							}
						} else {
							return {
								...item,
								nodeOne: updateNode(item.nodeOne),
								nodeTwo: updateNode(item.nodeTwo)
							}
						}
					})

					tables.push({
						table_type: tableType,
						match_type: matchType,
						table_level: tableLevel,
						levels: [
							{
								order_number: group.order_number,
								round: updatedOlympicPlusFormGrid
							}
						]
					})
				}
				else {
					const playersTable = group.nodes
						.flatMap((node) => {
							let playerObject

							if (node.type === NODE_TYPE_IN) {
								if (node.double_uid === null && node.player_uid === null) {
									return null
								} else if (tournamentData.players) {
									playerObject = tournamentData.players.find((player) => player.uid === node.player_uid)
									matchType = 'singles'
								} else if (tournamentData.doubles) {
									playerObject = tournamentData.doubles.find((doublesPlayer) => doublesPlayer.uid === node.double_uid)
									matchType = 'doubles'
								}

								if (playerObject) {
									return {
										...playerObject,
										nodeUid: node?.uid,
									}
								}
							}

							return null
						}).filter((player) => player !== null)

					tables.push({
						table_type: tableType,
						match_type: matchType,
						table_level: tableLevel,
						levels: [
							{
								order_number: group.order_number,
								players: playersTable,
								matches: group.matches
							}
						]
					})
				}
			})
	)

	const resultJSON = {
		version: packageJson.version,
		environment: window.location.hostname,
		tournament_name: tournamentData.name,
		description: tournament.start_date,
		stage: stage,
		tables: tables,
		page: 'draws'
	}

	return resultJSON
}